@import '~src/css/quasar.variables.sass', 'quasar/src/css/variables.sass'
// app global css in Sass form
.underline
  text-decoration: underline

.uploader
  .q-field__inner .q-field__control
    height: 5rem !important

  .q-field--auto-height.q-field--labeled .q-field__control-container
    display: flex
    justify-content: center
    align-items: center

  .ellipsis
    white-space: normal
    text-align: center
    top: auto

.bg-accent-light
  background: #c5e0e8

.ProseMirror p
  padding: 0.5rem

@for $i from 1 through 100
  .border-radius-#{$i}
    border-radius: #{$i}px !important
  .border-radius-top-#{$i}
    border-top-left-radius: #{$i}px !important
    border-top-right-radius: #{$i}px !important
  .border-radius-bottom-#{$i}
    border-bottom-left-radius: #{$i}px !important
    border-bottom-right-radius: #{$i}px !important
  .border-radius-left-#{$i}
    border-top-left-radius: #{$i}px !important
    border-bottom-left-radius: #{$i}px !important
  .border-radius-right-#{$i}
    border-top-right-radius: #{$i}px !important
    border-bottom-right-radius: #{$i}px !important
  .padding-side-#{$i}
    padding-left: #{$i}rem
    padding-right: #{$i}rem
  .margin-side-#{$i}
    padding-left: #{$i}rem
    padding-right: #{$i}rem

.tiptap-menubar, .editor__content, .bordered
  border: solid 0.05rem lightgrey

.black-border
  border: solid 0.05rem black

.accent-border
  border: solid 0.05rem $accent

.dashed-border
  border: dashed 0.05rem black

.ProseMirror, .editor__content
  min-height: 8rem

.linear-gradient
  background: linear-gradient(#EEF5F7, white)

.bg-interface
  background: white

.bg-interface-2
  background: linear-gradient(#f2f2f2, white)

.tiptap-menubar
  background-color: #FAFAFA !important
  color: $accent

$mypubli: #FDC300

.bg-mypubli-yellow
  background-color: #FDC300 !important

.text-mypubli-yellow
  color: #FDC300

$mypubli-blue: #F5FDFF
.bg-mypubli-blue
  background-color: #F5FDFF !important

.text-mypubli-blue
  color: #F5FDFF

$mypubli-purple: #FFF5F8
.bg-mypubli-purple
  background-color: #FFF5F8 !important

.text-mypubli-purple
  color: #FFF5F8

$mypubli-orange: #FFF8F6
.bg-mypubli-orange
  background-color: #FFF8F6 !important

.text-mypubli-orange
  color: #FFF8F6

@font-face
  font-family: 'icomoon'
  src: url('fonts/icomoon.eot?2woh3q')
  src: url('fonts/icomoon.eot?2woh3q#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?2woh3q') format('truetype'), url('fonts/icomoon.woff?2woh3q') format('woff'), url('fonts/icomoon.svg?2woh3q#icomoon') format('svg')
  font-weight: normal
  font-style: normal
  font-display: block

[class^="icon-"], [class*=" icon-"]
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important
  speak: never
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.icon-analyse:before
  content: "\e904"
  color: #fff

.icon-author:before
  content: "\e914"
  color: #fff

.icon-methodologie:before
  content: "\e969"
  color: #fff

.icon-rules:before
  content: "\e96e"
  color: #fff

.icon-tableetfigure:before
  content: "\e973"
  color: #fff

.icon-update:before
  content: "\e979"
  color: #fff

.icon-angle-up:before
  content: "\e900"

.icon-archive:before
  content: "\e901"

.icon-bars:before
  content: "\e902"
  color: rgba(0, 0, 0, 0.6)

.icon-chart-pie:before
  content: "\e903"

.icon-check:before
  content: "\e905"

.icon-ellipse-15:before
  content: "\e906"

.icon-exclamation-triangle:before
  content: "\e907"

.icon-file-invoice-dollar:before
  content: "\e908"

.icon-folder:before
  content: "\e909"

  margin-left: 0.35em

.icon-hourglass:before
  content: "\e90a"

.icon-angle-down:before
  content: "\e90b"

.icon-angle-left:before
  content: "\e90c"

.icon-arrow-right:before
  content: "\e90d"

.icon-arrow-alt-up:before
  content: "\e90e"

.icon-arrow-alt-down:before
  content: "\e90f"

.icon-arrow-circle-left:before
  content: "\e910"

.icon-at:before
  content: "\e911"

.icon-auteur:before
  content: "\e912"

.icon-award:before
  content: "\e913"

.icon-badge-percent:before
  content: "\e915"

.icon-ban:before
  content: "\e916"

.icon-bold:before
  content: "\e917"

.icon-bullseye-arrow:before
  content: "\e918"

.icon-calendar:before
  content: "\e919"

.icon-chart-line:before
  content: "\e91a"

.icon-check-circle:before
  content: "\e91b"

.icon-cog:before
  content: "\e91c"

.icon-comment-dots:before
  content: "\e91d"

.icon-conflict:before
  content: "\e91e"

.icon-credit-card-front:before
  content: "\e91f"

.icon-dashboard:before
  content: "\e920"

.icon-down:before
  content: "\e921"

.icon-ellipsis-h:before
  content: "\e922"

.icon-envelope:before
  content: "\e923"

.icon-facebook:before
  content: "\e924"

.icon-figures-sup .path1:before
  content: "\e925"
  color: $accent

.icon-figures-sup .path2:before
  content: "\e926"
  margin-left: -1.09765625em
  color: $accent

.icon-figures-sup .path3:before
  content: "\e927"
  margin-left: -1.09765625em
  color: $accent

.icon-figures-sup .path4:before
  content: "\e928"
  margin-left: -1.09765625em
  color: $accent

.icon-figures-sup .path5:before
  content: "\e929"
  margin-left: -1.09765625em
  color: $accent

.icon-file:before
  content: "\e92a"

.icon-file-alt:before
  content: "\e92b"

.icon-file-CSV:before
  content: "\e92c"

.icon-file-excel:before
  content: "\e92d"

.icon-file-image:before
  content: "\e92e"

.icon-file-PDF:before
  content: "\e92f"

.icon-file-plus:before
  content: "\e930"

.icon-file-XLSX:before
  content: "\e931"

.icon-filter:before
  content: "\e932"

.icon-flag:before
  content: "\e933"

.icon-graduation-cap:before
  content: "\e934"

.icon-grin:before
  content: "\e935"

.icon-guide:before
  content: "\e936"

.icon-hidden:before
  content: "\e937"

.icon-history:before
  content: "\e938"

.icon-icon-material-label-outline:before
  content: "\e939"

.icon-image:before
  content: "\e93a"

.icon-inbox-out:before
  content: "\e93b"

.icon-independant:before
  content: "\e93c"

.icon-info-circle:before
  content: "\e93d"

.icon-italic:before
  content: "\e93e"

.icon-link:before
  content: "\e93f"

.icon-medical:before
  content: "\e940"

.icon-money-bill:before
  content: "\e941"

.icon-outil-redac:before
  content: "\e942"

.icon-paper-plane:before
  content: "\e943"

.icon-phone:before
  content: "\e944"

.icon-poll-h:before
  content: "\e945"

.icon-preview:before
  content: "\e946"
  margin-left: 0.35em

.icon-preview-alt:before
  content: "\e946"

.icon-redo:before
  content: "\e947"

.icon-references:before
  content: "\e948"

.icon-ressources:before
  content: "\e949"

.icon-revue:before
  content: "\e94a"

.icon-rocket:before
  content: "\e94b"

.icon-share:before
  content: "\e94c"

.icon-shield-check:before
  content: "\e94d"

.icon-suscribe:before
  content: "\e94e"

.icon-tablesupp .path1:before
  content: "\e94f"
  color: rgb(0, 0, 0)

.icon-tablesupp .path2:before
  content: "\e950"
  margin-left: -1.1953125em
  color: rgb(0, 0, 0)

.icon-tablesupp .path3:before
  content: "\e951"
  margin-left: -1.1953125em
  color: rgb(255, 255, 255)

.icon-tablesupp .path4:before
  content: "\e952"
  margin-left: -1.1953125em
  color: rgb(0, 0, 0)

.icon-tablesupp .path5:before
  content: "\e953"
  margin-left: -1.1953125em
  color: rgb(255, 255, 255)

.icon-talents:before
  content: "\e954"

.icon-task:before
  content: "\e955"

.icon-template:before
  content: "\e956"
  margin-left: 0.35em

.icon-thumbs-up:before
  content: "\e957"

.icon-ic_ticket-alt:before
  content: "\e958"

.icon-ic_tip:before
  content: "\e959"

.icon-trash-alt:before
  content: "\e95a"

.icon-twitter:before
  content: "\e95b"

.icon-undo:before
  content: "\e95c"

.icon-up:before
  content: "\e95d"

.icon-user:before
  content: "\e95e"

.icon-user-circle:before
  content: "\e95f"

.icon-user-graduate:before
  content: "\e960"

.icon-users:before
  content: "\e961"

.icon-ic_users-cog:before
  content: "\e962"
  margin-left: 0.35em

.icon-video:before
  content: "\e963"

.icon-visible:before
  content: "\e964"

.icon-wallet:before
  content: "\e965"

.icon-files-empty:before
  content: "\e966"

.icon-linkedin:before
  content: "\e967"

.icon-newspaper:before
  content: "\e968"

.icon-pen-nib:before
  content: "\e96a"

.icon-plus:before
  content: "\e96b"

.icon-search:before
  content: "\e96c"

.icon-table:before
  content: "\e96d"

.icon-ticket-alt:before
  content: "\e96f"

.icon-times:before
  content: "\e970"

.icon-times-circle:before
  content: "\e971"

.icon-book:before
  content: "\e972"

.icon-closed-eye:before
  content: "\e974"

.icon-edit:before
  content: "\e975"

.icon-more:before
  content: "\e976"

.icon-user-plus:before
  content: "\e977"
  margin-left: 0.35em

.icon-users-cog:before
  content: "\e978"
  margin-left: 0.35em



