@charset "UTF-8";
.underline {
  text-decoration: underline;
}

.uploader .q-field__inner .q-field__control {
  height: 5rem !important;
}
.uploader .q-field--auto-height.q-field--labeled .q-field__control-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploader .ellipsis {
  white-space: normal;
  text-align: center;
  top: auto;
}

.bg-accent-light {
  background: #c5e0e8;
}

.ProseMirror p {
  padding: 0.5rem;
}

.border-radius-1 {
  border-radius: 1px !important;
}

.border-radius-top-1 {
  border-top-left-radius: 1px !important;
  border-top-right-radius: 1px !important;
}

.border-radius-bottom-1 {
  border-bottom-left-radius: 1px !important;
  border-bottom-right-radius: 1px !important;
}

.border-radius-left-1 {
  border-top-left-radius: 1px !important;
  border-bottom-left-radius: 1px !important;
}

.border-radius-right-1 {
  border-top-right-radius: 1px !important;
  border-bottom-right-radius: 1px !important;
}

.padding-side-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.margin-side-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.border-radius-2 {
  border-radius: 2px !important;
}

.border-radius-top-2 {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
}

.border-radius-bottom-2 {
  border-bottom-left-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.border-radius-left-2 {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.border-radius-right-2 {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.padding-side-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.margin-side-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.border-radius-3 {
  border-radius: 3px !important;
}

.border-radius-top-3 {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.border-radius-bottom-3 {
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.border-radius-left-3 {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.border-radius-right-3 {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.padding-side-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.margin-side-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-top-4 {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.border-radius-bottom-4 {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.border-radius-left-4 {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.border-radius-right-4 {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.padding-side-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.margin-side-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.border-radius-top-5 {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.border-radius-bottom-5 {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.border-radius-left-5 {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.border-radius-right-5 {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.padding-side-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.margin-side-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.border-radius-6 {
  border-radius: 6px !important;
}

.border-radius-top-6 {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.border-radius-bottom-6 {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.border-radius-left-6 {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.border-radius-right-6 {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.padding-side-6 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.margin-side-6 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.border-radius-7 {
  border-radius: 7px !important;
}

.border-radius-top-7 {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

.border-radius-bottom-7 {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.border-radius-left-7 {
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.border-radius-right-7 {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.padding-side-7 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.margin-side-7 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.border-radius-top-8 {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.border-radius-bottom-8 {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.border-radius-left-8 {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.border-radius-right-8 {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.padding-side-8 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.margin-side-8 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.border-radius-9 {
  border-radius: 9px !important;
}

.border-radius-top-9 {
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
}

.border-radius-bottom-9 {
  border-bottom-left-radius: 9px !important;
  border-bottom-right-radius: 9px !important;
}

.border-radius-left-9 {
  border-top-left-radius: 9px !important;
  border-bottom-left-radius: 9px !important;
}

.border-radius-right-9 {
  border-top-right-radius: 9px !important;
  border-bottom-right-radius: 9px !important;
}

.padding-side-9 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.margin-side-9 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-top-10 {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.border-radius-bottom-10 {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.border-radius-left-10 {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.border-radius-right-10 {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.padding-side-10 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.margin-side-10 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.border-radius-11 {
  border-radius: 11px !important;
}

.border-radius-top-11 {
  border-top-left-radius: 11px !important;
  border-top-right-radius: 11px !important;
}

.border-radius-bottom-11 {
  border-bottom-left-radius: 11px !important;
  border-bottom-right-radius: 11px !important;
}

.border-radius-left-11 {
  border-top-left-radius: 11px !important;
  border-bottom-left-radius: 11px !important;
}

.border-radius-right-11 {
  border-top-right-radius: 11px !important;
  border-bottom-right-radius: 11px !important;
}

.padding-side-11 {
  padding-left: 11rem;
  padding-right: 11rem;
}

.margin-side-11 {
  padding-left: 11rem;
  padding-right: 11rem;
}

.border-radius-12 {
  border-radius: 12px !important;
}

.border-radius-top-12 {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.border-radius-bottom-12 {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.border-radius-left-12 {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

.border-radius-right-12 {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.padding-side-12 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.margin-side-12 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.border-radius-13 {
  border-radius: 13px !important;
}

.border-radius-top-13 {
  border-top-left-radius: 13px !important;
  border-top-right-radius: 13px !important;
}

.border-radius-bottom-13 {
  border-bottom-left-radius: 13px !important;
  border-bottom-right-radius: 13px !important;
}

.border-radius-left-13 {
  border-top-left-radius: 13px !important;
  border-bottom-left-radius: 13px !important;
}

.border-radius-right-13 {
  border-top-right-radius: 13px !important;
  border-bottom-right-radius: 13px !important;
}

.padding-side-13 {
  padding-left: 13rem;
  padding-right: 13rem;
}

.margin-side-13 {
  padding-left: 13rem;
  padding-right: 13rem;
}

.border-radius-14 {
  border-radius: 14px !important;
}

.border-radius-top-14 {
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
}

.border-radius-bottom-14 {
  border-bottom-left-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}

.border-radius-left-14 {
  border-top-left-radius: 14px !important;
  border-bottom-left-radius: 14px !important;
}

.border-radius-right-14 {
  border-top-right-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}

.padding-side-14 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.margin-side-14 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.border-radius-15 {
  border-radius: 15px !important;
}

.border-radius-top-15 {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.border-radius-bottom-15 {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.border-radius-left-15 {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.border-radius-right-15 {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.padding-side-15 {
  padding-left: 15rem;
  padding-right: 15rem;
}

.margin-side-15 {
  padding-left: 15rem;
  padding-right: 15rem;
}

.border-radius-16 {
  border-radius: 16px !important;
}

.border-radius-top-16 {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.border-radius-bottom-16 {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.border-radius-left-16 {
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
}

.border-radius-right-16 {
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.padding-side-16 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.margin-side-16 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.border-radius-17 {
  border-radius: 17px !important;
}

.border-radius-top-17 {
  border-top-left-radius: 17px !important;
  border-top-right-radius: 17px !important;
}

.border-radius-bottom-17 {
  border-bottom-left-radius: 17px !important;
  border-bottom-right-radius: 17px !important;
}

.border-radius-left-17 {
  border-top-left-radius: 17px !important;
  border-bottom-left-radius: 17px !important;
}

.border-radius-right-17 {
  border-top-right-radius: 17px !important;
  border-bottom-right-radius: 17px !important;
}

.padding-side-17 {
  padding-left: 17rem;
  padding-right: 17rem;
}

.margin-side-17 {
  padding-left: 17rem;
  padding-right: 17rem;
}

.border-radius-18 {
  border-radius: 18px !important;
}

.border-radius-top-18 {
  border-top-left-radius: 18px !important;
  border-top-right-radius: 18px !important;
}

.border-radius-bottom-18 {
  border-bottom-left-radius: 18px !important;
  border-bottom-right-radius: 18px !important;
}

.border-radius-left-18 {
  border-top-left-radius: 18px !important;
  border-bottom-left-radius: 18px !important;
}

.border-radius-right-18 {
  border-top-right-radius: 18px !important;
  border-bottom-right-radius: 18px !important;
}

.padding-side-18 {
  padding-left: 18rem;
  padding-right: 18rem;
}

.margin-side-18 {
  padding-left: 18rem;
  padding-right: 18rem;
}

.border-radius-19 {
  border-radius: 19px !important;
}

.border-radius-top-19 {
  border-top-left-radius: 19px !important;
  border-top-right-radius: 19px !important;
}

.border-radius-bottom-19 {
  border-bottom-left-radius: 19px !important;
  border-bottom-right-radius: 19px !important;
}

.border-radius-left-19 {
  border-top-left-radius: 19px !important;
  border-bottom-left-radius: 19px !important;
}

.border-radius-right-19 {
  border-top-right-radius: 19px !important;
  border-bottom-right-radius: 19px !important;
}

.padding-side-19 {
  padding-left: 19rem;
  padding-right: 19rem;
}

.margin-side-19 {
  padding-left: 19rem;
  padding-right: 19rem;
}

.border-radius-20 {
  border-radius: 20px !important;
}

.border-radius-top-20 {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.border-radius-bottom-20 {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.border-radius-left-20 {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.border-radius-right-20 {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.padding-side-20 {
  padding-left: 20rem;
  padding-right: 20rem;
}

.margin-side-20 {
  padding-left: 20rem;
  padding-right: 20rem;
}

.border-radius-21 {
  border-radius: 21px !important;
}

.border-radius-top-21 {
  border-top-left-radius: 21px !important;
  border-top-right-radius: 21px !important;
}

.border-radius-bottom-21 {
  border-bottom-left-radius: 21px !important;
  border-bottom-right-radius: 21px !important;
}

.border-radius-left-21 {
  border-top-left-radius: 21px !important;
  border-bottom-left-radius: 21px !important;
}

.border-radius-right-21 {
  border-top-right-radius: 21px !important;
  border-bottom-right-radius: 21px !important;
}

.padding-side-21 {
  padding-left: 21rem;
  padding-right: 21rem;
}

.margin-side-21 {
  padding-left: 21rem;
  padding-right: 21rem;
}

.border-radius-22 {
  border-radius: 22px !important;
}

.border-radius-top-22 {
  border-top-left-radius: 22px !important;
  border-top-right-radius: 22px !important;
}

.border-radius-bottom-22 {
  border-bottom-left-radius: 22px !important;
  border-bottom-right-radius: 22px !important;
}

.border-radius-left-22 {
  border-top-left-radius: 22px !important;
  border-bottom-left-radius: 22px !important;
}

.border-radius-right-22 {
  border-top-right-radius: 22px !important;
  border-bottom-right-radius: 22px !important;
}

.padding-side-22 {
  padding-left: 22rem;
  padding-right: 22rem;
}

.margin-side-22 {
  padding-left: 22rem;
  padding-right: 22rem;
}

.border-radius-23 {
  border-radius: 23px !important;
}

.border-radius-top-23 {
  border-top-left-radius: 23px !important;
  border-top-right-radius: 23px !important;
}

.border-radius-bottom-23 {
  border-bottom-left-radius: 23px !important;
  border-bottom-right-radius: 23px !important;
}

.border-radius-left-23 {
  border-top-left-radius: 23px !important;
  border-bottom-left-radius: 23px !important;
}

.border-radius-right-23 {
  border-top-right-radius: 23px !important;
  border-bottom-right-radius: 23px !important;
}

.padding-side-23 {
  padding-left: 23rem;
  padding-right: 23rem;
}

.margin-side-23 {
  padding-left: 23rem;
  padding-right: 23rem;
}

.border-radius-24 {
  border-radius: 24px !important;
}

.border-radius-top-24 {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
}

.border-radius-bottom-24 {
  border-bottom-left-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}

.border-radius-left-24 {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
}

.border-radius-right-24 {
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}

.padding-side-24 {
  padding-left: 24rem;
  padding-right: 24rem;
}

.margin-side-24 {
  padding-left: 24rem;
  padding-right: 24rem;
}

.border-radius-25 {
  border-radius: 25px !important;
}

.border-radius-top-25 {
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
}

.border-radius-bottom-25 {
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

.border-radius-left-25 {
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
}

.border-radius-right-25 {
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

.padding-side-25 {
  padding-left: 25rem;
  padding-right: 25rem;
}

.margin-side-25 {
  padding-left: 25rem;
  padding-right: 25rem;
}

.border-radius-26 {
  border-radius: 26px !important;
}

.border-radius-top-26 {
  border-top-left-radius: 26px !important;
  border-top-right-radius: 26px !important;
}

.border-radius-bottom-26 {
  border-bottom-left-radius: 26px !important;
  border-bottom-right-radius: 26px !important;
}

.border-radius-left-26 {
  border-top-left-radius: 26px !important;
  border-bottom-left-radius: 26px !important;
}

.border-radius-right-26 {
  border-top-right-radius: 26px !important;
  border-bottom-right-radius: 26px !important;
}

.padding-side-26 {
  padding-left: 26rem;
  padding-right: 26rem;
}

.margin-side-26 {
  padding-left: 26rem;
  padding-right: 26rem;
}

.border-radius-27 {
  border-radius: 27px !important;
}

.border-radius-top-27 {
  border-top-left-radius: 27px !important;
  border-top-right-radius: 27px !important;
}

.border-radius-bottom-27 {
  border-bottom-left-radius: 27px !important;
  border-bottom-right-radius: 27px !important;
}

.border-radius-left-27 {
  border-top-left-radius: 27px !important;
  border-bottom-left-radius: 27px !important;
}

.border-radius-right-27 {
  border-top-right-radius: 27px !important;
  border-bottom-right-radius: 27px !important;
}

.padding-side-27 {
  padding-left: 27rem;
  padding-right: 27rem;
}

.margin-side-27 {
  padding-left: 27rem;
  padding-right: 27rem;
}

.border-radius-28 {
  border-radius: 28px !important;
}

.border-radius-top-28 {
  border-top-left-radius: 28px !important;
  border-top-right-radius: 28px !important;
}

.border-radius-bottom-28 {
  border-bottom-left-radius: 28px !important;
  border-bottom-right-radius: 28px !important;
}

.border-radius-left-28 {
  border-top-left-radius: 28px !important;
  border-bottom-left-radius: 28px !important;
}

.border-radius-right-28 {
  border-top-right-radius: 28px !important;
  border-bottom-right-radius: 28px !important;
}

.padding-side-28 {
  padding-left: 28rem;
  padding-right: 28rem;
}

.margin-side-28 {
  padding-left: 28rem;
  padding-right: 28rem;
}

.border-radius-29 {
  border-radius: 29px !important;
}

.border-radius-top-29 {
  border-top-left-radius: 29px !important;
  border-top-right-radius: 29px !important;
}

.border-radius-bottom-29 {
  border-bottom-left-radius: 29px !important;
  border-bottom-right-radius: 29px !important;
}

.border-radius-left-29 {
  border-top-left-radius: 29px !important;
  border-bottom-left-radius: 29px !important;
}

.border-radius-right-29 {
  border-top-right-radius: 29px !important;
  border-bottom-right-radius: 29px !important;
}

.padding-side-29 {
  padding-left: 29rem;
  padding-right: 29rem;
}

.margin-side-29 {
  padding-left: 29rem;
  padding-right: 29rem;
}

.border-radius-30 {
  border-radius: 30px !important;
}

.border-radius-top-30 {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}

.border-radius-bottom-30 {
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.border-radius-left-30 {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.border-radius-right-30 {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.padding-side-30 {
  padding-left: 30rem;
  padding-right: 30rem;
}

.margin-side-30 {
  padding-left: 30rem;
  padding-right: 30rem;
}

.border-radius-31 {
  border-radius: 31px !important;
}

.border-radius-top-31 {
  border-top-left-radius: 31px !important;
  border-top-right-radius: 31px !important;
}

.border-radius-bottom-31 {
  border-bottom-left-radius: 31px !important;
  border-bottom-right-radius: 31px !important;
}

.border-radius-left-31 {
  border-top-left-radius: 31px !important;
  border-bottom-left-radius: 31px !important;
}

.border-radius-right-31 {
  border-top-right-radius: 31px !important;
  border-bottom-right-radius: 31px !important;
}

.padding-side-31 {
  padding-left: 31rem;
  padding-right: 31rem;
}

.margin-side-31 {
  padding-left: 31rem;
  padding-right: 31rem;
}

.border-radius-32 {
  border-radius: 32px !important;
}

.border-radius-top-32 {
  border-top-left-radius: 32px !important;
  border-top-right-radius: 32px !important;
}

.border-radius-bottom-32 {
  border-bottom-left-radius: 32px !important;
  border-bottom-right-radius: 32px !important;
}

.border-radius-left-32 {
  border-top-left-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
}

.border-radius-right-32 {
  border-top-right-radius: 32px !important;
  border-bottom-right-radius: 32px !important;
}

.padding-side-32 {
  padding-left: 32rem;
  padding-right: 32rem;
}

.margin-side-32 {
  padding-left: 32rem;
  padding-right: 32rem;
}

.border-radius-33 {
  border-radius: 33px !important;
}

.border-radius-top-33 {
  border-top-left-radius: 33px !important;
  border-top-right-radius: 33px !important;
}

.border-radius-bottom-33 {
  border-bottom-left-radius: 33px !important;
  border-bottom-right-radius: 33px !important;
}

.border-radius-left-33 {
  border-top-left-radius: 33px !important;
  border-bottom-left-radius: 33px !important;
}

.border-radius-right-33 {
  border-top-right-radius: 33px !important;
  border-bottom-right-radius: 33px !important;
}

.padding-side-33 {
  padding-left: 33rem;
  padding-right: 33rem;
}

.margin-side-33 {
  padding-left: 33rem;
  padding-right: 33rem;
}

.border-radius-34 {
  border-radius: 34px !important;
}

.border-radius-top-34 {
  border-top-left-radius: 34px !important;
  border-top-right-radius: 34px !important;
}

.border-radius-bottom-34 {
  border-bottom-left-radius: 34px !important;
  border-bottom-right-radius: 34px !important;
}

.border-radius-left-34 {
  border-top-left-radius: 34px !important;
  border-bottom-left-radius: 34px !important;
}

.border-radius-right-34 {
  border-top-right-radius: 34px !important;
  border-bottom-right-radius: 34px !important;
}

.padding-side-34 {
  padding-left: 34rem;
  padding-right: 34rem;
}

.margin-side-34 {
  padding-left: 34rem;
  padding-right: 34rem;
}

.border-radius-35 {
  border-radius: 35px !important;
}

.border-radius-top-35 {
  border-top-left-radius: 35px !important;
  border-top-right-radius: 35px !important;
}

.border-radius-bottom-35 {
  border-bottom-left-radius: 35px !important;
  border-bottom-right-radius: 35px !important;
}

.border-radius-left-35 {
  border-top-left-radius: 35px !important;
  border-bottom-left-radius: 35px !important;
}

.border-radius-right-35 {
  border-top-right-radius: 35px !important;
  border-bottom-right-radius: 35px !important;
}

.padding-side-35 {
  padding-left: 35rem;
  padding-right: 35rem;
}

.margin-side-35 {
  padding-left: 35rem;
  padding-right: 35rem;
}

.border-radius-36 {
  border-radius: 36px !important;
}

.border-radius-top-36 {
  border-top-left-radius: 36px !important;
  border-top-right-radius: 36px !important;
}

.border-radius-bottom-36 {
  border-bottom-left-radius: 36px !important;
  border-bottom-right-radius: 36px !important;
}

.border-radius-left-36 {
  border-top-left-radius: 36px !important;
  border-bottom-left-radius: 36px !important;
}

.border-radius-right-36 {
  border-top-right-radius: 36px !important;
  border-bottom-right-radius: 36px !important;
}

.padding-side-36 {
  padding-left: 36rem;
  padding-right: 36rem;
}

.margin-side-36 {
  padding-left: 36rem;
  padding-right: 36rem;
}

.border-radius-37 {
  border-radius: 37px !important;
}

.border-radius-top-37 {
  border-top-left-radius: 37px !important;
  border-top-right-radius: 37px !important;
}

.border-radius-bottom-37 {
  border-bottom-left-radius: 37px !important;
  border-bottom-right-radius: 37px !important;
}

.border-radius-left-37 {
  border-top-left-radius: 37px !important;
  border-bottom-left-radius: 37px !important;
}

.border-radius-right-37 {
  border-top-right-radius: 37px !important;
  border-bottom-right-radius: 37px !important;
}

.padding-side-37 {
  padding-left: 37rem;
  padding-right: 37rem;
}

.margin-side-37 {
  padding-left: 37rem;
  padding-right: 37rem;
}

.border-radius-38 {
  border-radius: 38px !important;
}

.border-radius-top-38 {
  border-top-left-radius: 38px !important;
  border-top-right-radius: 38px !important;
}

.border-radius-bottom-38 {
  border-bottom-left-radius: 38px !important;
  border-bottom-right-radius: 38px !important;
}

.border-radius-left-38 {
  border-top-left-radius: 38px !important;
  border-bottom-left-radius: 38px !important;
}

.border-radius-right-38 {
  border-top-right-radius: 38px !important;
  border-bottom-right-radius: 38px !important;
}

.padding-side-38 {
  padding-left: 38rem;
  padding-right: 38rem;
}

.margin-side-38 {
  padding-left: 38rem;
  padding-right: 38rem;
}

.border-radius-39 {
  border-radius: 39px !important;
}

.border-radius-top-39 {
  border-top-left-radius: 39px !important;
  border-top-right-radius: 39px !important;
}

.border-radius-bottom-39 {
  border-bottom-left-radius: 39px !important;
  border-bottom-right-radius: 39px !important;
}

.border-radius-left-39 {
  border-top-left-radius: 39px !important;
  border-bottom-left-radius: 39px !important;
}

.border-radius-right-39 {
  border-top-right-radius: 39px !important;
  border-bottom-right-radius: 39px !important;
}

.padding-side-39 {
  padding-left: 39rem;
  padding-right: 39rem;
}

.margin-side-39 {
  padding-left: 39rem;
  padding-right: 39rem;
}

.border-radius-40 {
  border-radius: 40px !important;
}

.border-radius-top-40 {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
}

.border-radius-bottom-40 {
  border-bottom-left-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
}

.border-radius-left-40 {
  border-top-left-radius: 40px !important;
  border-bottom-left-radius: 40px !important;
}

.border-radius-right-40 {
  border-top-right-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
}

.padding-side-40 {
  padding-left: 40rem;
  padding-right: 40rem;
}

.margin-side-40 {
  padding-left: 40rem;
  padding-right: 40rem;
}

.border-radius-41 {
  border-radius: 41px !important;
}

.border-radius-top-41 {
  border-top-left-radius: 41px !important;
  border-top-right-radius: 41px !important;
}

.border-radius-bottom-41 {
  border-bottom-left-radius: 41px !important;
  border-bottom-right-radius: 41px !important;
}

.border-radius-left-41 {
  border-top-left-radius: 41px !important;
  border-bottom-left-radius: 41px !important;
}

.border-radius-right-41 {
  border-top-right-radius: 41px !important;
  border-bottom-right-radius: 41px !important;
}

.padding-side-41 {
  padding-left: 41rem;
  padding-right: 41rem;
}

.margin-side-41 {
  padding-left: 41rem;
  padding-right: 41rem;
}

.border-radius-42 {
  border-radius: 42px !important;
}

.border-radius-top-42 {
  border-top-left-radius: 42px !important;
  border-top-right-radius: 42px !important;
}

.border-radius-bottom-42 {
  border-bottom-left-radius: 42px !important;
  border-bottom-right-radius: 42px !important;
}

.border-radius-left-42 {
  border-top-left-radius: 42px !important;
  border-bottom-left-radius: 42px !important;
}

.border-radius-right-42 {
  border-top-right-radius: 42px !important;
  border-bottom-right-radius: 42px !important;
}

.padding-side-42 {
  padding-left: 42rem;
  padding-right: 42rem;
}

.margin-side-42 {
  padding-left: 42rem;
  padding-right: 42rem;
}

.border-radius-43 {
  border-radius: 43px !important;
}

.border-radius-top-43 {
  border-top-left-radius: 43px !important;
  border-top-right-radius: 43px !important;
}

.border-radius-bottom-43 {
  border-bottom-left-radius: 43px !important;
  border-bottom-right-radius: 43px !important;
}

.border-radius-left-43 {
  border-top-left-radius: 43px !important;
  border-bottom-left-radius: 43px !important;
}

.border-radius-right-43 {
  border-top-right-radius: 43px !important;
  border-bottom-right-radius: 43px !important;
}

.padding-side-43 {
  padding-left: 43rem;
  padding-right: 43rem;
}

.margin-side-43 {
  padding-left: 43rem;
  padding-right: 43rem;
}

.border-radius-44 {
  border-radius: 44px !important;
}

.border-radius-top-44 {
  border-top-left-radius: 44px !important;
  border-top-right-radius: 44px !important;
}

.border-radius-bottom-44 {
  border-bottom-left-radius: 44px !important;
  border-bottom-right-radius: 44px !important;
}

.border-radius-left-44 {
  border-top-left-radius: 44px !important;
  border-bottom-left-radius: 44px !important;
}

.border-radius-right-44 {
  border-top-right-radius: 44px !important;
  border-bottom-right-radius: 44px !important;
}

.padding-side-44 {
  padding-left: 44rem;
  padding-right: 44rem;
}

.margin-side-44 {
  padding-left: 44rem;
  padding-right: 44rem;
}

.border-radius-45 {
  border-radius: 45px !important;
}

.border-radius-top-45 {
  border-top-left-radius: 45px !important;
  border-top-right-radius: 45px !important;
}

.border-radius-bottom-45 {
  border-bottom-left-radius: 45px !important;
  border-bottom-right-radius: 45px !important;
}

.border-radius-left-45 {
  border-top-left-radius: 45px !important;
  border-bottom-left-radius: 45px !important;
}

.border-radius-right-45 {
  border-top-right-radius: 45px !important;
  border-bottom-right-radius: 45px !important;
}

.padding-side-45 {
  padding-left: 45rem;
  padding-right: 45rem;
}

.margin-side-45 {
  padding-left: 45rem;
  padding-right: 45rem;
}

.border-radius-46 {
  border-radius: 46px !important;
}

.border-radius-top-46 {
  border-top-left-radius: 46px !important;
  border-top-right-radius: 46px !important;
}

.border-radius-bottom-46 {
  border-bottom-left-radius: 46px !important;
  border-bottom-right-radius: 46px !important;
}

.border-radius-left-46 {
  border-top-left-radius: 46px !important;
  border-bottom-left-radius: 46px !important;
}

.border-radius-right-46 {
  border-top-right-radius: 46px !important;
  border-bottom-right-radius: 46px !important;
}

.padding-side-46 {
  padding-left: 46rem;
  padding-right: 46rem;
}

.margin-side-46 {
  padding-left: 46rem;
  padding-right: 46rem;
}

.border-radius-47 {
  border-radius: 47px !important;
}

.border-radius-top-47 {
  border-top-left-radius: 47px !important;
  border-top-right-radius: 47px !important;
}

.border-radius-bottom-47 {
  border-bottom-left-radius: 47px !important;
  border-bottom-right-radius: 47px !important;
}

.border-radius-left-47 {
  border-top-left-radius: 47px !important;
  border-bottom-left-radius: 47px !important;
}

.border-radius-right-47 {
  border-top-right-radius: 47px !important;
  border-bottom-right-radius: 47px !important;
}

.padding-side-47 {
  padding-left: 47rem;
  padding-right: 47rem;
}

.margin-side-47 {
  padding-left: 47rem;
  padding-right: 47rem;
}

.border-radius-48 {
  border-radius: 48px !important;
}

.border-radius-top-48 {
  border-top-left-radius: 48px !important;
  border-top-right-radius: 48px !important;
}

.border-radius-bottom-48 {
  border-bottom-left-radius: 48px !important;
  border-bottom-right-radius: 48px !important;
}

.border-radius-left-48 {
  border-top-left-radius: 48px !important;
  border-bottom-left-radius: 48px !important;
}

.border-radius-right-48 {
  border-top-right-radius: 48px !important;
  border-bottom-right-radius: 48px !important;
}

.padding-side-48 {
  padding-left: 48rem;
  padding-right: 48rem;
}

.margin-side-48 {
  padding-left: 48rem;
  padding-right: 48rem;
}

.border-radius-49 {
  border-radius: 49px !important;
}

.border-radius-top-49 {
  border-top-left-radius: 49px !important;
  border-top-right-radius: 49px !important;
}

.border-radius-bottom-49 {
  border-bottom-left-radius: 49px !important;
  border-bottom-right-radius: 49px !important;
}

.border-radius-left-49 {
  border-top-left-radius: 49px !important;
  border-bottom-left-radius: 49px !important;
}

.border-radius-right-49 {
  border-top-right-radius: 49px !important;
  border-bottom-right-radius: 49px !important;
}

.padding-side-49 {
  padding-left: 49rem;
  padding-right: 49rem;
}

.margin-side-49 {
  padding-left: 49rem;
  padding-right: 49rem;
}

.border-radius-50 {
  border-radius: 50px !important;
}

.border-radius-top-50 {
  border-top-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
}

.border-radius-bottom-50 {
  border-bottom-left-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}

.border-radius-left-50 {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.border-radius-right-50 {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}

.padding-side-50 {
  padding-left: 50rem;
  padding-right: 50rem;
}

.margin-side-50 {
  padding-left: 50rem;
  padding-right: 50rem;
}

.border-radius-51 {
  border-radius: 51px !important;
}

.border-radius-top-51 {
  border-top-left-radius: 51px !important;
  border-top-right-radius: 51px !important;
}

.border-radius-bottom-51 {
  border-bottom-left-radius: 51px !important;
  border-bottom-right-radius: 51px !important;
}

.border-radius-left-51 {
  border-top-left-radius: 51px !important;
  border-bottom-left-radius: 51px !important;
}

.border-radius-right-51 {
  border-top-right-radius: 51px !important;
  border-bottom-right-radius: 51px !important;
}

.padding-side-51 {
  padding-left: 51rem;
  padding-right: 51rem;
}

.margin-side-51 {
  padding-left: 51rem;
  padding-right: 51rem;
}

.border-radius-52 {
  border-radius: 52px !important;
}

.border-radius-top-52 {
  border-top-left-radius: 52px !important;
  border-top-right-radius: 52px !important;
}

.border-radius-bottom-52 {
  border-bottom-left-radius: 52px !important;
  border-bottom-right-radius: 52px !important;
}

.border-radius-left-52 {
  border-top-left-radius: 52px !important;
  border-bottom-left-radius: 52px !important;
}

.border-radius-right-52 {
  border-top-right-radius: 52px !important;
  border-bottom-right-radius: 52px !important;
}

.padding-side-52 {
  padding-left: 52rem;
  padding-right: 52rem;
}

.margin-side-52 {
  padding-left: 52rem;
  padding-right: 52rem;
}

.border-radius-53 {
  border-radius: 53px !important;
}

.border-radius-top-53 {
  border-top-left-radius: 53px !important;
  border-top-right-radius: 53px !important;
}

.border-radius-bottom-53 {
  border-bottom-left-radius: 53px !important;
  border-bottom-right-radius: 53px !important;
}

.border-radius-left-53 {
  border-top-left-radius: 53px !important;
  border-bottom-left-radius: 53px !important;
}

.border-radius-right-53 {
  border-top-right-radius: 53px !important;
  border-bottom-right-radius: 53px !important;
}

.padding-side-53 {
  padding-left: 53rem;
  padding-right: 53rem;
}

.margin-side-53 {
  padding-left: 53rem;
  padding-right: 53rem;
}

.border-radius-54 {
  border-radius: 54px !important;
}

.border-radius-top-54 {
  border-top-left-radius: 54px !important;
  border-top-right-radius: 54px !important;
}

.border-radius-bottom-54 {
  border-bottom-left-radius: 54px !important;
  border-bottom-right-radius: 54px !important;
}

.border-radius-left-54 {
  border-top-left-radius: 54px !important;
  border-bottom-left-radius: 54px !important;
}

.border-radius-right-54 {
  border-top-right-radius: 54px !important;
  border-bottom-right-radius: 54px !important;
}

.padding-side-54 {
  padding-left: 54rem;
  padding-right: 54rem;
}

.margin-side-54 {
  padding-left: 54rem;
  padding-right: 54rem;
}

.border-radius-55 {
  border-radius: 55px !important;
}

.border-radius-top-55 {
  border-top-left-radius: 55px !important;
  border-top-right-radius: 55px !important;
}

.border-radius-bottom-55 {
  border-bottom-left-radius: 55px !important;
  border-bottom-right-radius: 55px !important;
}

.border-radius-left-55 {
  border-top-left-radius: 55px !important;
  border-bottom-left-radius: 55px !important;
}

.border-radius-right-55 {
  border-top-right-radius: 55px !important;
  border-bottom-right-radius: 55px !important;
}

.padding-side-55 {
  padding-left: 55rem;
  padding-right: 55rem;
}

.margin-side-55 {
  padding-left: 55rem;
  padding-right: 55rem;
}

.border-radius-56 {
  border-radius: 56px !important;
}

.border-radius-top-56 {
  border-top-left-radius: 56px !important;
  border-top-right-radius: 56px !important;
}

.border-radius-bottom-56 {
  border-bottom-left-radius: 56px !important;
  border-bottom-right-radius: 56px !important;
}

.border-radius-left-56 {
  border-top-left-radius: 56px !important;
  border-bottom-left-radius: 56px !important;
}

.border-radius-right-56 {
  border-top-right-radius: 56px !important;
  border-bottom-right-radius: 56px !important;
}

.padding-side-56 {
  padding-left: 56rem;
  padding-right: 56rem;
}

.margin-side-56 {
  padding-left: 56rem;
  padding-right: 56rem;
}

.border-radius-57 {
  border-radius: 57px !important;
}

.border-radius-top-57 {
  border-top-left-radius: 57px !important;
  border-top-right-radius: 57px !important;
}

.border-radius-bottom-57 {
  border-bottom-left-radius: 57px !important;
  border-bottom-right-radius: 57px !important;
}

.border-radius-left-57 {
  border-top-left-radius: 57px !important;
  border-bottom-left-radius: 57px !important;
}

.border-radius-right-57 {
  border-top-right-radius: 57px !important;
  border-bottom-right-radius: 57px !important;
}

.padding-side-57 {
  padding-left: 57rem;
  padding-right: 57rem;
}

.margin-side-57 {
  padding-left: 57rem;
  padding-right: 57rem;
}

.border-radius-58 {
  border-radius: 58px !important;
}

.border-radius-top-58 {
  border-top-left-radius: 58px !important;
  border-top-right-radius: 58px !important;
}

.border-radius-bottom-58 {
  border-bottom-left-radius: 58px !important;
  border-bottom-right-radius: 58px !important;
}

.border-radius-left-58 {
  border-top-left-radius: 58px !important;
  border-bottom-left-radius: 58px !important;
}

.border-radius-right-58 {
  border-top-right-radius: 58px !important;
  border-bottom-right-radius: 58px !important;
}

.padding-side-58 {
  padding-left: 58rem;
  padding-right: 58rem;
}

.margin-side-58 {
  padding-left: 58rem;
  padding-right: 58rem;
}

.border-radius-59 {
  border-radius: 59px !important;
}

.border-radius-top-59 {
  border-top-left-radius: 59px !important;
  border-top-right-radius: 59px !important;
}

.border-radius-bottom-59 {
  border-bottom-left-radius: 59px !important;
  border-bottom-right-radius: 59px !important;
}

.border-radius-left-59 {
  border-top-left-radius: 59px !important;
  border-bottom-left-radius: 59px !important;
}

.border-radius-right-59 {
  border-top-right-radius: 59px !important;
  border-bottom-right-radius: 59px !important;
}

.padding-side-59 {
  padding-left: 59rem;
  padding-right: 59rem;
}

.margin-side-59 {
  padding-left: 59rem;
  padding-right: 59rem;
}

.border-radius-60 {
  border-radius: 60px !important;
}

.border-radius-top-60 {
  border-top-left-radius: 60px !important;
  border-top-right-radius: 60px !important;
}

.border-radius-bottom-60 {
  border-bottom-left-radius: 60px !important;
  border-bottom-right-radius: 60px !important;
}

.border-radius-left-60 {
  border-top-left-radius: 60px !important;
  border-bottom-left-radius: 60px !important;
}

.border-radius-right-60 {
  border-top-right-radius: 60px !important;
  border-bottom-right-radius: 60px !important;
}

.padding-side-60 {
  padding-left: 60rem;
  padding-right: 60rem;
}

.margin-side-60 {
  padding-left: 60rem;
  padding-right: 60rem;
}

.border-radius-61 {
  border-radius: 61px !important;
}

.border-radius-top-61 {
  border-top-left-radius: 61px !important;
  border-top-right-radius: 61px !important;
}

.border-radius-bottom-61 {
  border-bottom-left-radius: 61px !important;
  border-bottom-right-radius: 61px !important;
}

.border-radius-left-61 {
  border-top-left-radius: 61px !important;
  border-bottom-left-radius: 61px !important;
}

.border-radius-right-61 {
  border-top-right-radius: 61px !important;
  border-bottom-right-radius: 61px !important;
}

.padding-side-61 {
  padding-left: 61rem;
  padding-right: 61rem;
}

.margin-side-61 {
  padding-left: 61rem;
  padding-right: 61rem;
}

.border-radius-62 {
  border-radius: 62px !important;
}

.border-radius-top-62 {
  border-top-left-radius: 62px !important;
  border-top-right-radius: 62px !important;
}

.border-radius-bottom-62 {
  border-bottom-left-radius: 62px !important;
  border-bottom-right-radius: 62px !important;
}

.border-radius-left-62 {
  border-top-left-radius: 62px !important;
  border-bottom-left-radius: 62px !important;
}

.border-radius-right-62 {
  border-top-right-radius: 62px !important;
  border-bottom-right-radius: 62px !important;
}

.padding-side-62 {
  padding-left: 62rem;
  padding-right: 62rem;
}

.margin-side-62 {
  padding-left: 62rem;
  padding-right: 62rem;
}

.border-radius-63 {
  border-radius: 63px !important;
}

.border-radius-top-63 {
  border-top-left-radius: 63px !important;
  border-top-right-radius: 63px !important;
}

.border-radius-bottom-63 {
  border-bottom-left-radius: 63px !important;
  border-bottom-right-radius: 63px !important;
}

.border-radius-left-63 {
  border-top-left-radius: 63px !important;
  border-bottom-left-radius: 63px !important;
}

.border-radius-right-63 {
  border-top-right-radius: 63px !important;
  border-bottom-right-radius: 63px !important;
}

.padding-side-63 {
  padding-left: 63rem;
  padding-right: 63rem;
}

.margin-side-63 {
  padding-left: 63rem;
  padding-right: 63rem;
}

.border-radius-64 {
  border-radius: 64px !important;
}

.border-radius-top-64 {
  border-top-left-radius: 64px !important;
  border-top-right-radius: 64px !important;
}

.border-radius-bottom-64 {
  border-bottom-left-radius: 64px !important;
  border-bottom-right-radius: 64px !important;
}

.border-radius-left-64 {
  border-top-left-radius: 64px !important;
  border-bottom-left-radius: 64px !important;
}

.border-radius-right-64 {
  border-top-right-radius: 64px !important;
  border-bottom-right-radius: 64px !important;
}

.padding-side-64 {
  padding-left: 64rem;
  padding-right: 64rem;
}

.margin-side-64 {
  padding-left: 64rem;
  padding-right: 64rem;
}

.border-radius-65 {
  border-radius: 65px !important;
}

.border-radius-top-65 {
  border-top-left-radius: 65px !important;
  border-top-right-radius: 65px !important;
}

.border-radius-bottom-65 {
  border-bottom-left-radius: 65px !important;
  border-bottom-right-radius: 65px !important;
}

.border-radius-left-65 {
  border-top-left-radius: 65px !important;
  border-bottom-left-radius: 65px !important;
}

.border-radius-right-65 {
  border-top-right-radius: 65px !important;
  border-bottom-right-radius: 65px !important;
}

.padding-side-65 {
  padding-left: 65rem;
  padding-right: 65rem;
}

.margin-side-65 {
  padding-left: 65rem;
  padding-right: 65rem;
}

.border-radius-66 {
  border-radius: 66px !important;
}

.border-radius-top-66 {
  border-top-left-radius: 66px !important;
  border-top-right-radius: 66px !important;
}

.border-radius-bottom-66 {
  border-bottom-left-radius: 66px !important;
  border-bottom-right-radius: 66px !important;
}

.border-radius-left-66 {
  border-top-left-radius: 66px !important;
  border-bottom-left-radius: 66px !important;
}

.border-radius-right-66 {
  border-top-right-radius: 66px !important;
  border-bottom-right-radius: 66px !important;
}

.padding-side-66 {
  padding-left: 66rem;
  padding-right: 66rem;
}

.margin-side-66 {
  padding-left: 66rem;
  padding-right: 66rem;
}

.border-radius-67 {
  border-radius: 67px !important;
}

.border-radius-top-67 {
  border-top-left-radius: 67px !important;
  border-top-right-radius: 67px !important;
}

.border-radius-bottom-67 {
  border-bottom-left-radius: 67px !important;
  border-bottom-right-radius: 67px !important;
}

.border-radius-left-67 {
  border-top-left-radius: 67px !important;
  border-bottom-left-radius: 67px !important;
}

.border-radius-right-67 {
  border-top-right-radius: 67px !important;
  border-bottom-right-radius: 67px !important;
}

.padding-side-67 {
  padding-left: 67rem;
  padding-right: 67rem;
}

.margin-side-67 {
  padding-left: 67rem;
  padding-right: 67rem;
}

.border-radius-68 {
  border-radius: 68px !important;
}

.border-radius-top-68 {
  border-top-left-radius: 68px !important;
  border-top-right-radius: 68px !important;
}

.border-radius-bottom-68 {
  border-bottom-left-radius: 68px !important;
  border-bottom-right-radius: 68px !important;
}

.border-radius-left-68 {
  border-top-left-radius: 68px !important;
  border-bottom-left-radius: 68px !important;
}

.border-radius-right-68 {
  border-top-right-radius: 68px !important;
  border-bottom-right-radius: 68px !important;
}

.padding-side-68 {
  padding-left: 68rem;
  padding-right: 68rem;
}

.margin-side-68 {
  padding-left: 68rem;
  padding-right: 68rem;
}

.border-radius-69 {
  border-radius: 69px !important;
}

.border-radius-top-69 {
  border-top-left-radius: 69px !important;
  border-top-right-radius: 69px !important;
}

.border-radius-bottom-69 {
  border-bottom-left-radius: 69px !important;
  border-bottom-right-radius: 69px !important;
}

.border-radius-left-69 {
  border-top-left-radius: 69px !important;
  border-bottom-left-radius: 69px !important;
}

.border-radius-right-69 {
  border-top-right-radius: 69px !important;
  border-bottom-right-radius: 69px !important;
}

.padding-side-69 {
  padding-left: 69rem;
  padding-right: 69rem;
}

.margin-side-69 {
  padding-left: 69rem;
  padding-right: 69rem;
}

.border-radius-70 {
  border-radius: 70px !important;
}

.border-radius-top-70 {
  border-top-left-radius: 70px !important;
  border-top-right-radius: 70px !important;
}

.border-radius-bottom-70 {
  border-bottom-left-radius: 70px !important;
  border-bottom-right-radius: 70px !important;
}

.border-radius-left-70 {
  border-top-left-radius: 70px !important;
  border-bottom-left-radius: 70px !important;
}

.border-radius-right-70 {
  border-top-right-radius: 70px !important;
  border-bottom-right-radius: 70px !important;
}

.padding-side-70 {
  padding-left: 70rem;
  padding-right: 70rem;
}

.margin-side-70 {
  padding-left: 70rem;
  padding-right: 70rem;
}

.border-radius-71 {
  border-radius: 71px !important;
}

.border-radius-top-71 {
  border-top-left-radius: 71px !important;
  border-top-right-radius: 71px !important;
}

.border-radius-bottom-71 {
  border-bottom-left-radius: 71px !important;
  border-bottom-right-radius: 71px !important;
}

.border-radius-left-71 {
  border-top-left-radius: 71px !important;
  border-bottom-left-radius: 71px !important;
}

.border-radius-right-71 {
  border-top-right-radius: 71px !important;
  border-bottom-right-radius: 71px !important;
}

.padding-side-71 {
  padding-left: 71rem;
  padding-right: 71rem;
}

.margin-side-71 {
  padding-left: 71rem;
  padding-right: 71rem;
}

.border-radius-72 {
  border-radius: 72px !important;
}

.border-radius-top-72 {
  border-top-left-radius: 72px !important;
  border-top-right-radius: 72px !important;
}

.border-radius-bottom-72 {
  border-bottom-left-radius: 72px !important;
  border-bottom-right-radius: 72px !important;
}

.border-radius-left-72 {
  border-top-left-radius: 72px !important;
  border-bottom-left-radius: 72px !important;
}

.border-radius-right-72 {
  border-top-right-radius: 72px !important;
  border-bottom-right-radius: 72px !important;
}

.padding-side-72 {
  padding-left: 72rem;
  padding-right: 72rem;
}

.margin-side-72 {
  padding-left: 72rem;
  padding-right: 72rem;
}

.border-radius-73 {
  border-radius: 73px !important;
}

.border-radius-top-73 {
  border-top-left-radius: 73px !important;
  border-top-right-radius: 73px !important;
}

.border-radius-bottom-73 {
  border-bottom-left-radius: 73px !important;
  border-bottom-right-radius: 73px !important;
}

.border-radius-left-73 {
  border-top-left-radius: 73px !important;
  border-bottom-left-radius: 73px !important;
}

.border-radius-right-73 {
  border-top-right-radius: 73px !important;
  border-bottom-right-radius: 73px !important;
}

.padding-side-73 {
  padding-left: 73rem;
  padding-right: 73rem;
}

.margin-side-73 {
  padding-left: 73rem;
  padding-right: 73rem;
}

.border-radius-74 {
  border-radius: 74px !important;
}

.border-radius-top-74 {
  border-top-left-radius: 74px !important;
  border-top-right-radius: 74px !important;
}

.border-radius-bottom-74 {
  border-bottom-left-radius: 74px !important;
  border-bottom-right-radius: 74px !important;
}

.border-radius-left-74 {
  border-top-left-radius: 74px !important;
  border-bottom-left-radius: 74px !important;
}

.border-radius-right-74 {
  border-top-right-radius: 74px !important;
  border-bottom-right-radius: 74px !important;
}

.padding-side-74 {
  padding-left: 74rem;
  padding-right: 74rem;
}

.margin-side-74 {
  padding-left: 74rem;
  padding-right: 74rem;
}

.border-radius-75 {
  border-radius: 75px !important;
}

.border-radius-top-75 {
  border-top-left-radius: 75px !important;
  border-top-right-radius: 75px !important;
}

.border-radius-bottom-75 {
  border-bottom-left-radius: 75px !important;
  border-bottom-right-radius: 75px !important;
}

.border-radius-left-75 {
  border-top-left-radius: 75px !important;
  border-bottom-left-radius: 75px !important;
}

.border-radius-right-75 {
  border-top-right-radius: 75px !important;
  border-bottom-right-radius: 75px !important;
}

.padding-side-75 {
  padding-left: 75rem;
  padding-right: 75rem;
}

.margin-side-75 {
  padding-left: 75rem;
  padding-right: 75rem;
}

.border-radius-76 {
  border-radius: 76px !important;
}

.border-radius-top-76 {
  border-top-left-radius: 76px !important;
  border-top-right-radius: 76px !important;
}

.border-radius-bottom-76 {
  border-bottom-left-radius: 76px !important;
  border-bottom-right-radius: 76px !important;
}

.border-radius-left-76 {
  border-top-left-radius: 76px !important;
  border-bottom-left-radius: 76px !important;
}

.border-radius-right-76 {
  border-top-right-radius: 76px !important;
  border-bottom-right-radius: 76px !important;
}

.padding-side-76 {
  padding-left: 76rem;
  padding-right: 76rem;
}

.margin-side-76 {
  padding-left: 76rem;
  padding-right: 76rem;
}

.border-radius-77 {
  border-radius: 77px !important;
}

.border-radius-top-77 {
  border-top-left-radius: 77px !important;
  border-top-right-radius: 77px !important;
}

.border-radius-bottom-77 {
  border-bottom-left-radius: 77px !important;
  border-bottom-right-radius: 77px !important;
}

.border-radius-left-77 {
  border-top-left-radius: 77px !important;
  border-bottom-left-radius: 77px !important;
}

.border-radius-right-77 {
  border-top-right-radius: 77px !important;
  border-bottom-right-radius: 77px !important;
}

.padding-side-77 {
  padding-left: 77rem;
  padding-right: 77rem;
}

.margin-side-77 {
  padding-left: 77rem;
  padding-right: 77rem;
}

.border-radius-78 {
  border-radius: 78px !important;
}

.border-radius-top-78 {
  border-top-left-radius: 78px !important;
  border-top-right-radius: 78px !important;
}

.border-radius-bottom-78 {
  border-bottom-left-radius: 78px !important;
  border-bottom-right-radius: 78px !important;
}

.border-radius-left-78 {
  border-top-left-radius: 78px !important;
  border-bottom-left-radius: 78px !important;
}

.border-radius-right-78 {
  border-top-right-radius: 78px !important;
  border-bottom-right-radius: 78px !important;
}

.padding-side-78 {
  padding-left: 78rem;
  padding-right: 78rem;
}

.margin-side-78 {
  padding-left: 78rem;
  padding-right: 78rem;
}

.border-radius-79 {
  border-radius: 79px !important;
}

.border-radius-top-79 {
  border-top-left-radius: 79px !important;
  border-top-right-radius: 79px !important;
}

.border-radius-bottom-79 {
  border-bottom-left-radius: 79px !important;
  border-bottom-right-radius: 79px !important;
}

.border-radius-left-79 {
  border-top-left-radius: 79px !important;
  border-bottom-left-radius: 79px !important;
}

.border-radius-right-79 {
  border-top-right-radius: 79px !important;
  border-bottom-right-radius: 79px !important;
}

.padding-side-79 {
  padding-left: 79rem;
  padding-right: 79rem;
}

.margin-side-79 {
  padding-left: 79rem;
  padding-right: 79rem;
}

.border-radius-80 {
  border-radius: 80px !important;
}

.border-radius-top-80 {
  border-top-left-radius: 80px !important;
  border-top-right-radius: 80px !important;
}

.border-radius-bottom-80 {
  border-bottom-left-radius: 80px !important;
  border-bottom-right-radius: 80px !important;
}

.border-radius-left-80 {
  border-top-left-radius: 80px !important;
  border-bottom-left-radius: 80px !important;
}

.border-radius-right-80 {
  border-top-right-radius: 80px !important;
  border-bottom-right-radius: 80px !important;
}

.padding-side-80 {
  padding-left: 80rem;
  padding-right: 80rem;
}

.margin-side-80 {
  padding-left: 80rem;
  padding-right: 80rem;
}

.border-radius-81 {
  border-radius: 81px !important;
}

.border-radius-top-81 {
  border-top-left-radius: 81px !important;
  border-top-right-radius: 81px !important;
}

.border-radius-bottom-81 {
  border-bottom-left-radius: 81px !important;
  border-bottom-right-radius: 81px !important;
}

.border-radius-left-81 {
  border-top-left-radius: 81px !important;
  border-bottom-left-radius: 81px !important;
}

.border-radius-right-81 {
  border-top-right-radius: 81px !important;
  border-bottom-right-radius: 81px !important;
}

.padding-side-81 {
  padding-left: 81rem;
  padding-right: 81rem;
}

.margin-side-81 {
  padding-left: 81rem;
  padding-right: 81rem;
}

.border-radius-82 {
  border-radius: 82px !important;
}

.border-radius-top-82 {
  border-top-left-radius: 82px !important;
  border-top-right-radius: 82px !important;
}

.border-radius-bottom-82 {
  border-bottom-left-radius: 82px !important;
  border-bottom-right-radius: 82px !important;
}

.border-radius-left-82 {
  border-top-left-radius: 82px !important;
  border-bottom-left-radius: 82px !important;
}

.border-radius-right-82 {
  border-top-right-radius: 82px !important;
  border-bottom-right-radius: 82px !important;
}

.padding-side-82 {
  padding-left: 82rem;
  padding-right: 82rem;
}

.margin-side-82 {
  padding-left: 82rem;
  padding-right: 82rem;
}

.border-radius-83 {
  border-radius: 83px !important;
}

.border-radius-top-83 {
  border-top-left-radius: 83px !important;
  border-top-right-radius: 83px !important;
}

.border-radius-bottom-83 {
  border-bottom-left-radius: 83px !important;
  border-bottom-right-radius: 83px !important;
}

.border-radius-left-83 {
  border-top-left-radius: 83px !important;
  border-bottom-left-radius: 83px !important;
}

.border-radius-right-83 {
  border-top-right-radius: 83px !important;
  border-bottom-right-radius: 83px !important;
}

.padding-side-83 {
  padding-left: 83rem;
  padding-right: 83rem;
}

.margin-side-83 {
  padding-left: 83rem;
  padding-right: 83rem;
}

.border-radius-84 {
  border-radius: 84px !important;
}

.border-radius-top-84 {
  border-top-left-radius: 84px !important;
  border-top-right-radius: 84px !important;
}

.border-radius-bottom-84 {
  border-bottom-left-radius: 84px !important;
  border-bottom-right-radius: 84px !important;
}

.border-radius-left-84 {
  border-top-left-radius: 84px !important;
  border-bottom-left-radius: 84px !important;
}

.border-radius-right-84 {
  border-top-right-radius: 84px !important;
  border-bottom-right-radius: 84px !important;
}

.padding-side-84 {
  padding-left: 84rem;
  padding-right: 84rem;
}

.margin-side-84 {
  padding-left: 84rem;
  padding-right: 84rem;
}

.border-radius-85 {
  border-radius: 85px !important;
}

.border-radius-top-85 {
  border-top-left-radius: 85px !important;
  border-top-right-radius: 85px !important;
}

.border-radius-bottom-85 {
  border-bottom-left-radius: 85px !important;
  border-bottom-right-radius: 85px !important;
}

.border-radius-left-85 {
  border-top-left-radius: 85px !important;
  border-bottom-left-radius: 85px !important;
}

.border-radius-right-85 {
  border-top-right-radius: 85px !important;
  border-bottom-right-radius: 85px !important;
}

.padding-side-85 {
  padding-left: 85rem;
  padding-right: 85rem;
}

.margin-side-85 {
  padding-left: 85rem;
  padding-right: 85rem;
}

.border-radius-86 {
  border-radius: 86px !important;
}

.border-radius-top-86 {
  border-top-left-radius: 86px !important;
  border-top-right-radius: 86px !important;
}

.border-radius-bottom-86 {
  border-bottom-left-radius: 86px !important;
  border-bottom-right-radius: 86px !important;
}

.border-radius-left-86 {
  border-top-left-radius: 86px !important;
  border-bottom-left-radius: 86px !important;
}

.border-radius-right-86 {
  border-top-right-radius: 86px !important;
  border-bottom-right-radius: 86px !important;
}

.padding-side-86 {
  padding-left: 86rem;
  padding-right: 86rem;
}

.margin-side-86 {
  padding-left: 86rem;
  padding-right: 86rem;
}

.border-radius-87 {
  border-radius: 87px !important;
}

.border-radius-top-87 {
  border-top-left-radius: 87px !important;
  border-top-right-radius: 87px !important;
}

.border-radius-bottom-87 {
  border-bottom-left-radius: 87px !important;
  border-bottom-right-radius: 87px !important;
}

.border-radius-left-87 {
  border-top-left-radius: 87px !important;
  border-bottom-left-radius: 87px !important;
}

.border-radius-right-87 {
  border-top-right-radius: 87px !important;
  border-bottom-right-radius: 87px !important;
}

.padding-side-87 {
  padding-left: 87rem;
  padding-right: 87rem;
}

.margin-side-87 {
  padding-left: 87rem;
  padding-right: 87rem;
}

.border-radius-88 {
  border-radius: 88px !important;
}

.border-radius-top-88 {
  border-top-left-radius: 88px !important;
  border-top-right-radius: 88px !important;
}

.border-radius-bottom-88 {
  border-bottom-left-radius: 88px !important;
  border-bottom-right-radius: 88px !important;
}

.border-radius-left-88 {
  border-top-left-radius: 88px !important;
  border-bottom-left-radius: 88px !important;
}

.border-radius-right-88 {
  border-top-right-radius: 88px !important;
  border-bottom-right-radius: 88px !important;
}

.padding-side-88 {
  padding-left: 88rem;
  padding-right: 88rem;
}

.margin-side-88 {
  padding-left: 88rem;
  padding-right: 88rem;
}

.border-radius-89 {
  border-radius: 89px !important;
}

.border-radius-top-89 {
  border-top-left-radius: 89px !important;
  border-top-right-radius: 89px !important;
}

.border-radius-bottom-89 {
  border-bottom-left-radius: 89px !important;
  border-bottom-right-radius: 89px !important;
}

.border-radius-left-89 {
  border-top-left-radius: 89px !important;
  border-bottom-left-radius: 89px !important;
}

.border-radius-right-89 {
  border-top-right-radius: 89px !important;
  border-bottom-right-radius: 89px !important;
}

.padding-side-89 {
  padding-left: 89rem;
  padding-right: 89rem;
}

.margin-side-89 {
  padding-left: 89rem;
  padding-right: 89rem;
}

.border-radius-90 {
  border-radius: 90px !important;
}

.border-radius-top-90 {
  border-top-left-radius: 90px !important;
  border-top-right-radius: 90px !important;
}

.border-radius-bottom-90 {
  border-bottom-left-radius: 90px !important;
  border-bottom-right-radius: 90px !important;
}

.border-radius-left-90 {
  border-top-left-radius: 90px !important;
  border-bottom-left-radius: 90px !important;
}

.border-radius-right-90 {
  border-top-right-radius: 90px !important;
  border-bottom-right-radius: 90px !important;
}

.padding-side-90 {
  padding-left: 90rem;
  padding-right: 90rem;
}

.margin-side-90 {
  padding-left: 90rem;
  padding-right: 90rem;
}

.border-radius-91 {
  border-radius: 91px !important;
}

.border-radius-top-91 {
  border-top-left-radius: 91px !important;
  border-top-right-radius: 91px !important;
}

.border-radius-bottom-91 {
  border-bottom-left-radius: 91px !important;
  border-bottom-right-radius: 91px !important;
}

.border-radius-left-91 {
  border-top-left-radius: 91px !important;
  border-bottom-left-radius: 91px !important;
}

.border-radius-right-91 {
  border-top-right-radius: 91px !important;
  border-bottom-right-radius: 91px !important;
}

.padding-side-91 {
  padding-left: 91rem;
  padding-right: 91rem;
}

.margin-side-91 {
  padding-left: 91rem;
  padding-right: 91rem;
}

.border-radius-92 {
  border-radius: 92px !important;
}

.border-radius-top-92 {
  border-top-left-radius: 92px !important;
  border-top-right-radius: 92px !important;
}

.border-radius-bottom-92 {
  border-bottom-left-radius: 92px !important;
  border-bottom-right-radius: 92px !important;
}

.border-radius-left-92 {
  border-top-left-radius: 92px !important;
  border-bottom-left-radius: 92px !important;
}

.border-radius-right-92 {
  border-top-right-radius: 92px !important;
  border-bottom-right-radius: 92px !important;
}

.padding-side-92 {
  padding-left: 92rem;
  padding-right: 92rem;
}

.margin-side-92 {
  padding-left: 92rem;
  padding-right: 92rem;
}

.border-radius-93 {
  border-radius: 93px !important;
}

.border-radius-top-93 {
  border-top-left-radius: 93px !important;
  border-top-right-radius: 93px !important;
}

.border-radius-bottom-93 {
  border-bottom-left-radius: 93px !important;
  border-bottom-right-radius: 93px !important;
}

.border-radius-left-93 {
  border-top-left-radius: 93px !important;
  border-bottom-left-radius: 93px !important;
}

.border-radius-right-93 {
  border-top-right-radius: 93px !important;
  border-bottom-right-radius: 93px !important;
}

.padding-side-93 {
  padding-left: 93rem;
  padding-right: 93rem;
}

.margin-side-93 {
  padding-left: 93rem;
  padding-right: 93rem;
}

.border-radius-94 {
  border-radius: 94px !important;
}

.border-radius-top-94 {
  border-top-left-radius: 94px !important;
  border-top-right-radius: 94px !important;
}

.border-radius-bottom-94 {
  border-bottom-left-radius: 94px !important;
  border-bottom-right-radius: 94px !important;
}

.border-radius-left-94 {
  border-top-left-radius: 94px !important;
  border-bottom-left-radius: 94px !important;
}

.border-radius-right-94 {
  border-top-right-radius: 94px !important;
  border-bottom-right-radius: 94px !important;
}

.padding-side-94 {
  padding-left: 94rem;
  padding-right: 94rem;
}

.margin-side-94 {
  padding-left: 94rem;
  padding-right: 94rem;
}

.border-radius-95 {
  border-radius: 95px !important;
}

.border-radius-top-95 {
  border-top-left-radius: 95px !important;
  border-top-right-radius: 95px !important;
}

.border-radius-bottom-95 {
  border-bottom-left-radius: 95px !important;
  border-bottom-right-radius: 95px !important;
}

.border-radius-left-95 {
  border-top-left-radius: 95px !important;
  border-bottom-left-radius: 95px !important;
}

.border-radius-right-95 {
  border-top-right-radius: 95px !important;
  border-bottom-right-radius: 95px !important;
}

.padding-side-95 {
  padding-left: 95rem;
  padding-right: 95rem;
}

.margin-side-95 {
  padding-left: 95rem;
  padding-right: 95rem;
}

.border-radius-96 {
  border-radius: 96px !important;
}

.border-radius-top-96 {
  border-top-left-radius: 96px !important;
  border-top-right-radius: 96px !important;
}

.border-radius-bottom-96 {
  border-bottom-left-radius: 96px !important;
  border-bottom-right-radius: 96px !important;
}

.border-radius-left-96 {
  border-top-left-radius: 96px !important;
  border-bottom-left-radius: 96px !important;
}

.border-radius-right-96 {
  border-top-right-radius: 96px !important;
  border-bottom-right-radius: 96px !important;
}

.padding-side-96 {
  padding-left: 96rem;
  padding-right: 96rem;
}

.margin-side-96 {
  padding-left: 96rem;
  padding-right: 96rem;
}

.border-radius-97 {
  border-radius: 97px !important;
}

.border-radius-top-97 {
  border-top-left-radius: 97px !important;
  border-top-right-radius: 97px !important;
}

.border-radius-bottom-97 {
  border-bottom-left-radius: 97px !important;
  border-bottom-right-radius: 97px !important;
}

.border-radius-left-97 {
  border-top-left-radius: 97px !important;
  border-bottom-left-radius: 97px !important;
}

.border-radius-right-97 {
  border-top-right-radius: 97px !important;
  border-bottom-right-radius: 97px !important;
}

.padding-side-97 {
  padding-left: 97rem;
  padding-right: 97rem;
}

.margin-side-97 {
  padding-left: 97rem;
  padding-right: 97rem;
}

.border-radius-98 {
  border-radius: 98px !important;
}

.border-radius-top-98 {
  border-top-left-radius: 98px !important;
  border-top-right-radius: 98px !important;
}

.border-radius-bottom-98 {
  border-bottom-left-radius: 98px !important;
  border-bottom-right-radius: 98px !important;
}

.border-radius-left-98 {
  border-top-left-radius: 98px !important;
  border-bottom-left-radius: 98px !important;
}

.border-radius-right-98 {
  border-top-right-radius: 98px !important;
  border-bottom-right-radius: 98px !important;
}

.padding-side-98 {
  padding-left: 98rem;
  padding-right: 98rem;
}

.margin-side-98 {
  padding-left: 98rem;
  padding-right: 98rem;
}

.border-radius-99 {
  border-radius: 99px !important;
}

.border-radius-top-99 {
  border-top-left-radius: 99px !important;
  border-top-right-radius: 99px !important;
}

.border-radius-bottom-99 {
  border-bottom-left-radius: 99px !important;
  border-bottom-right-radius: 99px !important;
}

.border-radius-left-99 {
  border-top-left-radius: 99px !important;
  border-bottom-left-radius: 99px !important;
}

.border-radius-right-99 {
  border-top-right-radius: 99px !important;
  border-bottom-right-radius: 99px !important;
}

.padding-side-99 {
  padding-left: 99rem;
  padding-right: 99rem;
}

.margin-side-99 {
  padding-left: 99rem;
  padding-right: 99rem;
}

.border-radius-100 {
  border-radius: 100px !important;
}

.border-radius-top-100 {
  border-top-left-radius: 100px !important;
  border-top-right-radius: 100px !important;
}

.border-radius-bottom-100 {
  border-bottom-left-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
}

.border-radius-left-100 {
  border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
}

.border-radius-right-100 {
  border-top-right-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
}

.padding-side-100 {
  padding-left: 100rem;
  padding-right: 100rem;
}

.margin-side-100 {
  padding-left: 100rem;
  padding-right: 100rem;
}

.tiptap-menubar, .editor__content, .bordered {
  border: solid 0.05rem lightgrey;
}

.black-border {
  border: solid 0.05rem black;
}

.accent-border {
  border: solid 0.05rem #5496AB;
}

.dashed-border {
  border: dashed 0.05rem black;
}

.ProseMirror, .editor__content {
  min-height: 8rem;
}

.linear-gradient {
  background: linear-gradient(#EEF5F7, white);
}

.bg-interface {
  background: white;
}

.bg-interface-2 {
  background: linear-gradient(#f2f2f2, white);
}

.tiptap-menubar {
  background-color: #FAFAFA !important;
  color: #5496AB;
}

.bg-mypubli-yellow {
  background-color: #FDC300 !important;
}

.text-mypubli-yellow {
  color: #FDC300;
}

.bg-mypubli-blue {
  background-color: #F5FDFF !important;
}

.text-mypubli-blue {
  color: #F5FDFF;
}

.bg-mypubli-purple {
  background-color: #FFF5F8 !important;
}

.text-mypubli-purple {
  color: #FFF5F8;
}

.bg-mypubli-orange {
  background-color: #FFF8F6 !important;
}

.text-mypubli-orange {
  color: #FFF8F6;
}

@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?2woh3q");
  src: url("fonts/icomoon.eot?2woh3q#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?2woh3q") format("truetype"), url("fonts/icomoon.woff?2woh3q") format("woff"), url("fonts/icomoon.svg?2woh3q#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-analyse:before {
  content: "";
  color: #fff;
}

.icon-author:before {
  content: "";
  color: #fff;
}

.icon-methodologie:before {
  content: "";
  color: #fff;
}

.icon-rules:before {
  content: "";
  color: #fff;
}

.icon-tableetfigure:before {
  content: "";
  color: #fff;
}

.icon-update:before {
  content: "";
  color: #fff;
}

.icon-angle-up:before {
  content: "";
}

.icon-archive:before {
  content: "";
}

.icon-bars:before {
  content: "";
  color: rgba(0, 0, 0, 0.6);
}

.icon-chart-pie:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-ellipse-15:before {
  content: "";
}

.icon-exclamation-triangle:before {
  content: "";
}

.icon-file-invoice-dollar:before {
  content: "";
}

.icon-folder:before {
  content: "";
  margin-left: 0.35em;
}

.icon-hourglass:before {
  content: "";
}

.icon-angle-down:before {
  content: "";
}

.icon-angle-left:before {
  content: "";
}

.icon-arrow-right:before {
  content: "";
}

.icon-arrow-alt-up:before {
  content: "";
}

.icon-arrow-alt-down:before {
  content: "";
}

.icon-arrow-circle-left:before {
  content: "";
}

.icon-at:before {
  content: "";
}

.icon-auteur:before {
  content: "";
}

.icon-award:before {
  content: "";
}

.icon-badge-percent:before {
  content: "";
}

.icon-ban:before {
  content: "";
}

.icon-bold:before {
  content: "";
}

.icon-bullseye-arrow:before {
  content: "";
}

.icon-calendar:before {
  content: "";
}

.icon-chart-line:before {
  content: "";
}

.icon-check-circle:before {
  content: "";
}

.icon-cog:before {
  content: "";
}

.icon-comment-dots:before {
  content: "";
}

.icon-conflict:before {
  content: "";
}

.icon-credit-card-front:before {
  content: "";
}

.icon-dashboard:before {
  content: "";
}

.icon-down:before {
  content: "";
}

.icon-ellipsis-h:before {
  content: "";
}

.icon-envelope:before {
  content: "";
}

.icon-facebook:before {
  content: "";
}

.icon-figures-sup .path1:before {
  content: "";
  color: #5496AB;
}

.icon-figures-sup .path2:before {
  content: "";
  margin-left: -1.09765625em;
  color: #5496AB;
}

.icon-figures-sup .path3:before {
  content: "";
  margin-left: -1.09765625em;
  color: #5496AB;
}

.icon-figures-sup .path4:before {
  content: "";
  margin-left: -1.09765625em;
  color: #5496AB;
}

.icon-figures-sup .path5:before {
  content: "";
  margin-left: -1.09765625em;
  color: #5496AB;
}

.icon-file:before {
  content: "";
}

.icon-file-alt:before {
  content: "";
}

.icon-file-CSV:before {
  content: "";
}

.icon-file-excel:before {
  content: "";
}

.icon-file-image:before {
  content: "";
}

.icon-file-PDF:before {
  content: "";
}

.icon-file-plus:before {
  content: "";
}

.icon-file-XLSX:before {
  content: "";
}

.icon-filter:before {
  content: "";
}

.icon-flag:before {
  content: "";
}

.icon-graduation-cap:before {
  content: "";
}

.icon-grin:before {
  content: "";
}

.icon-guide:before {
  content: "";
}

.icon-hidden:before {
  content: "";
}

.icon-history:before {
  content: "";
}

.icon-icon-material-label-outline:before {
  content: "";
}

.icon-image:before {
  content: "";
}

.icon-inbox-out:before {
  content: "";
}

.icon-independant:before {
  content: "";
}

.icon-info-circle:before {
  content: "";
}

.icon-italic:before {
  content: "";
}

.icon-link:before {
  content: "";
}

.icon-medical:before {
  content: "";
}

.icon-money-bill:before {
  content: "";
}

.icon-outil-redac:before {
  content: "";
}

.icon-paper-plane:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-poll-h:before {
  content: "";
}

.icon-preview:before {
  content: "";
  margin-left: 0.35em;
}

.icon-preview-alt:before {
  content: "";
}

.icon-redo:before {
  content: "";
}

.icon-references:before {
  content: "";
}

.icon-ressources:before {
  content: "";
}

.icon-revue:before {
  content: "";
}

.icon-rocket:before {
  content: "";
}

.icon-share:before {
  content: "";
}

.icon-shield-check:before {
  content: "";
}

.icon-suscribe:before {
  content: "";
}

.icon-tablesupp .path1:before {
  content: "";
  color: black;
}

.icon-tablesupp .path2:before {
  content: "";
  margin-left: -1.1953125em;
  color: black;
}

.icon-tablesupp .path3:before {
  content: "";
  margin-left: -1.1953125em;
  color: white;
}

.icon-tablesupp .path4:before {
  content: "";
  margin-left: -1.1953125em;
  color: black;
}

.icon-tablesupp .path5:before {
  content: "";
  margin-left: -1.1953125em;
  color: white;
}

.icon-talents:before {
  content: "";
}

.icon-task:before {
  content: "";
}

.icon-template:before {
  content: "";
  margin-left: 0.35em;
}

.icon-thumbs-up:before {
  content: "";
}

.icon-ic_ticket-alt:before {
  content: "";
}

.icon-ic_tip:before {
  content: "";
}

.icon-trash-alt:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

.icon-undo:before {
  content: "";
}

.icon-up:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-user-circle:before {
  content: "";
}

.icon-user-graduate:before {
  content: "";
}

.icon-users:before {
  content: "";
}

.icon-ic_users-cog:before {
  content: "";
  margin-left: 0.35em;
}

.icon-video:before {
  content: "";
}

.icon-visible:before {
  content: "";
}

.icon-wallet:before {
  content: "";
}

.icon-files-empty:before {
  content: "";
}

.icon-linkedin:before {
  content: "";
}

.icon-newspaper:before {
  content: "";
}

.icon-pen-nib:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-table:before {
  content: "";
}

.icon-ticket-alt:before {
  content: "";
}

.icon-times:before {
  content: "";
}

.icon-times-circle:before {
  content: "";
}

.icon-book:before {
  content: "";
}

.icon-closed-eye:before {
  content: "";
}

.icon-edit:before {
  content: "";
}

.icon-more:before {
  content: "";
}

.icon-user-plus:before {
  content: "";
  margin-left: 0.35em;
}

.icon-users-cog:before {
  content: "";
  margin-left: 0.35em;
}